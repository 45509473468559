import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Social from '../components/Social';

import headshot from '../assets/jonathan_herman_headshot.jpg';

export default function Home() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        component='img'
        sx={{
          display: 'block',
          height: '100vh',
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 0%',
        }}
        alt='Jonathan Herman Headshot'
        src={headshot}
      />

      <Box
        sx={{
          backgroundColor: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography component='h1' variant='h1' sx={{ p: '20px', fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem', lg: '5rem', xl: '6rem' } }}>
          Jonathan Herman
        </Typography>
        <Typography sx={{ p: '20px', fontSize: '1rem' }}>
          Classically trained bass-baritone singer and songwriter.
        </Typography>
        <Box sx={{ p: 2 }}>
          <Social />
        </Box>
      </Box>
    </Box>
  );
}
