import React from 'react';

import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

// import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Social() {
    return (
        <Stack direction='row' spacing={2} justifyContent='center'>
            {/* <Link href="https://twitter.com/JH_BassBaritone" target="_blank" rel="noopener">
                <TwitterIcon />
            </Link>
            <Link href="https://www.facebook.com/jonathanhermanbassbaritone" target="_blank" rel="noopener">
                <FacebookIcon />
            </Link> */}
            <Link href="https://www.youtube.com/channel/UCScKyISrDq_M0fhWGazaXsA" target="_blank" rel="noopener">
                <YouTubeIcon />
            </Link>
            <Link href="http://instagram.com/hermanentertainment" target="_blank" rel="noopener">
              <InstagramIcon />
            </Link>
        </Stack>
    )
}